import * as React from 'react';
import { RichTextRender } from 'src/cms';
import { ContentSection } from 'src/components/layout/layout';
import { BackLink } from 'src/components/lib/navlinks/back';
import { Title } from './style';

export const DocPage = ({ title, body }: { title: string; body: any }) => {
  return (
    <ContentSection>
      <BackLink />
      <Title>{title}</Title>
      <RichTextRender data={body} />
    </ContentSection>
  );
};

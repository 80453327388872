import styled from 'styled-components';
import tw from 'twin.macro';

export const Title = styled.div`
  font-family: 'Almarai';
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 52px;

  ${tw`
    pb-4
  `}
`;

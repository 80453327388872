import * as React from 'react';
import { useGetAllPrivacyPolicy } from 'src/cms';
import { Layout } from 'src/components/layout/layout';
import { DocPage } from 'src/components/lib/docPage';

const PrivacyPolicyPage = () => {
  const [privacyPolicy] = useGetAllPrivacyPolicy();

  return (
    <Layout>
      <DocPage title="Privacy Policy" body={privacyPolicy.privacyPolicy} />
    </Layout>
  );
};
export default PrivacyPolicyPage;
